.input-form .col-form-label{
    font-weight: normal;
}

.form-error {
    color: red;
    font-style: italic;
}

/*
.input-form .form-error {
    text-align: right;
    display: inline;
    float: left;
    padding: 0px 2px;
}
*/

.form-error {
    text-align: right;
    display: inline;
    float: left;
    padding: 0px 2px;
}