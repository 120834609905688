.fixed-top {
    top: -40px;
    transform: translateY(40px);
    transition: transform .3s;
}

#navbar_top{
    background-color: #cbac59!important;
    box-shadow:0px 2px 5px 0px rgb(0 0 0 / 30%);
}

#main_nav{
    transition: ease 1s;
}

.about-panel{
    padding: 4px 16px;
    cursor: default;
}

.dropdown, .cursor-pointer-fixe{
    cursor:pointer!important;
}

.app-logo{
    background-image: url("../../Assets/img/logo-cdlp.png");
    background-position: center;
    background-size: cover;
    height: 72px;
    width: 72px;
}